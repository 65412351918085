<template>
  <div>
    <div class="page-header clear-filter">
      <parallax
        class="page-header-image imageWhiteBlack"
        style="
          background-image: url('img/vienna.jpg');
          opacity: 0.15;
          filter: grayscale(100%);
        "
      >
      </parallax>
      <div class="container">
        <div class="card">
          <div
            class="tab-content tab-space text-left tab-content-padding text-center"
          >
            <div class="container" style="color: black">
              <div class="row">row1</div>
              <div class="row">row2</div>
              <div class="row">row3</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>