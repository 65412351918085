var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-header clear-filter"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"tab-content tab-space text-left tab-content-padding text-center"},[_c('div',{staticClass:"container",staticStyle:{"color":"black"}},[_c('div',{staticClass:"title"},[_vm._v("Prüfungen")]),_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},_vm._l((_vm.examResults),function(exam){return _c('div',{key:exam.id},[_c('router-link',{staticClass:"btn btn-round btn-question",attrs:{"to":{
                  name: 'resultSummary',
                  params: {
                    examStudentId: exam.id,
                    examDate: exam.created_at,
                    examType: exam.examType,
                  },
                }}},[_c('div',[_vm._v(" "+_vm._s(exam.examType === "all" ? "Ganze Prüfung" : exam.examType === "block1" ? "Block 1 Prüfung" : exam.examType === "block2" ? "Block 2 Prüfung " : "Nach Sicht Prüfung")+" "+_vm._s(_vm.$moment(exam.created_at).format("DD-MM-YYYY HH:mm"))+" ")])])],1)}),0)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }