<template>
  <div class="page-header clear-filter" filter-color="orange">
    <div class="page-header-image"></div>
    <div class="content">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">
          <card type="login" plain>
            <div slot="header" class="logo-container">
              <img v-lazy="'img/logo2.png'" alt="" style="width: 150px" />
            </div>

            <div class="form-group no-border input-lg input-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <i class="input-group-text now-ui-icons users_circle-08"></i>
                </div>
                <input
                  v-model="username"
                  placeholder="username"
                  class="form-control"
                  aria-describedby="addon-right addon-left"
                />
              </div>
            </div>

            <div class="form-group no-border input-lg input-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <i class="input-group-text now-ui-icons text_caps-small"></i>
                </div>
                <input
                  v-model="password"
                  placeholder="password"
                  class="form-control"
                  aria-describedby="addon-right addon-left"
                  type="password"
                />
              </div>
            </div>

            <template slot="raw-content">
              <div class="card-footer text-center">
                <button
                  class="btn btn-primary btn-round btn-lg btn-block"
                  @click="login"
                >
                  Login
                </button>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
    <v-dialog style="color: black" />
  </div>
</template>
<script>
import { Card, Button, FormGroupInput } from "@/components";
export default {
  name: "login-page",
  bodyClass: "login-page",
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      username: null,
      password: null,
    };
  },
  methods: {
    login: function () {
      this.$store
        .dispatch("AUTH_REQUEST", {
          identifier: this.username,
          password: this.password,
        })
        .then(() => {
          this.$store.commit("setShowLoginButton", false);
          this.currentUser = this.$store.getters.getUser;
          if (this.currentUser.userRole.id == 1) {
            this.$router.push("/userList");
          } else {
            this.$router.push("/result");
          }
        })
        .catch((err) => {
          if (err === "blockedDate") {
            this.$modal.show("dialog", {
              title: "Login Fehler",
              text:
                "Ihr Konto ist nicht mehr gültig bitte kontaktieren Sie ihre Taxischule",
              buttons: [
                {
                  title: "Schließen",
                  handler: () => {
                    this.$modal.hide("dialog");
                  },
                },
              ],
            });
          } else {
            this.$modal.show("dialog", {
              title: "Login Fehler",
              text: "Bitte kontrollieren Sie Ihre Einloggdaten",
              buttons: [
                {
                  title: "Schließen",
                  handler: () => {
                    this.$modal.hide("dialog");
                  },
                },
              ],
            });
          }
        });
    },
  },
};
</script>
<style></style>
