<template>
  <div>
    <div class="page-header clear-filter">
      <div v-if="question" class="container">
        <div
          class="row"
          style="
            color: black;
            font-weight: bold;
            text-align: center;
            justify-content: center;
            font-size: large;
            display: flex;
            align-item: center;
          "
        >
        <div class="col-12 col-sm-12">
            {{ categories[question.categoryId - 1].name }}
          </div>
          <!-- <div class="col-3 col-sm-1">
            <BaseTimer @timeUp="timerUp" ref="timer"></BaseTimer>
          </div> -->
          
        </div>
        <div class="card">
          <div
            class="tab-content tab-space text-left tab-content-padding text-center"
          >
            <div class="container">
              <div class="row">
                <question :question="question" @choiceSelected="choiceSelected">
                </question>
              </div>
              <div id="animate6" class="animate6">
                <div class="row">
                  <button
                    :disabled="this.nextButtonEnabled"
                    class="btn btn-question"
                    @click="
                      nextQuestion(
                        question.order,
                        question.examId,
                        question.examQuestionId,
                        question.examStudentId,
                        question.examType
                      )
                    "
                  >
                    {{butonText}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import question from "./PreQuestion";
// import BaseTimer from "./BaseTimer";

export default {
  name: "preexam",
  bodyClass: "preexam-page",
  data() {
    return {
      baseUrl: "http://" + this.apiBaseHost + ":" + this.apiBasePort,
      question: null,
      currentSelection: null,
      nextButtonEnabled: true,
      categories: null,
      butonText:"Nächste Frage"
    };
  },
  components: {
    question,
  },
  mounted() {
    let examType = this.$route.params.examType;
    let userId = this.$store.getters.getUserId;
    this.order = 1;
    this.$axios
      .post(this.baseUrl +"/getExam", {
        userId: userId,
        examType: examType,
      })
      .then((response) => (this.question = response.data));
    this.$axios
      .get(this.baseUrl +"/getCategoryList")
      .then((response) => (this.categories = response.data));
  },
  methods: {
    nextQuestion(
      currentOrder,
      examId,
      examQuestionId,
      examStudentId,
      examType
    ) {
      this.order = currentOrder;
      document.getElementById("animate6").classList.remove("animate6");
      setTimeout(() => {
        document.getElementById("animate6").classList.add("animate6");
      }, 1);

      this.$axios
        .post(this.baseUrl +"/nextQuestionForPreExam", {
          order: currentOrder,
          choiceId: this.currentSelection,
          examId: examId,
          examQuestionId: examQuestionId,
          examStudentId: examStudentId,
          examType: examType,
        })
        .then((response) => {
          if (currentOrder == 96 && examType == "all") {
            this.$router.push({
              name: "examChoose"
            });
          } else if (currentOrder == 51 && examType == "block1") {
            this.$router.push({
              name: "examChoose"
            });
          } else if (currentOrder == 45 && examType == "block2") {
            this.$router.push({
              name: "examChoose"
              
            });
          } else if (currentOrder == 27 && examType == "nachSicht") {
            this.$router.push({
              name: "examChoose"
              
            });
          }else {
            this.question = response.data;
          }
        });

        if ((currentOrder == 95 && examType == "all") || (currentOrder == 50 && examType == "block1") || (currentOrder == 44 && examType == "block2")  ) {
            this.butonText=" LETZTE FRAGE..."
        }
      this.nextButtonEnabled = true;
      window.scrollTo(0,0);

    },
    choiceSelected(id) {
      this.currentSelection = id;
      this.nextButtonEnabled = false;
    },
  },
};
</script>

<style>
.animate6 {
  animation-timing-function: ease;
  position: relative;
  animation: mymove 1.25s;
  animation-fill-mode: forwards;
  float: right;
  color: black;
  margin-right:20px;
}



.btn-question4 {
  color: black !important;
  width: 90%;
  /* background-color: #ffee38 !important; */
  font-weight: bold !important;
}


.btn-question4:hover {
  color: white !important;
}

.btn-question4::selection {
  color: white !important;
}


@keyframes mymove {
  from {
    left: -1000px;
  }
  to {
    left: 0;
  }
}
</style>
