<template>
  <div id="app">
    <router-view name="header" />
    <div class="wrapper">
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>
export default {
  created: function () {
    let self = this;
    this.$axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.message.includes("401")) {
          self.$store.commit("setShowLoginButton", true);
          self.$store.dispatch("AUTH_LOGOUT");
          self.$router.push({ path: "login" });
        }
        throw err;
      });
    });
  },
};
</script>
