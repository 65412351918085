<template>
  <div v-if="question" style="width: 100%">
    <div style="width: 100%">
      <div class="row">
        <div
          v-if="question.imageUrl"
          class="col-sm-12 col-md-12 col-lg-6 col-xl-6"
        >
          <div class="container" style="height: 300px; align: center">
            <img :src="question.imageUrl.url" style="height: 100%" />
          </div>
        </div>
        <div
          :class="
            question.imageUrl
              ? 'col-sm-12 col-md-12 col-lg-6 col-xl-6'
              : 'col-12'
          "
        >
          <div class="container">
            <div class="row">
              <div class="animate1" id="animate1">
                <p style="color: black; font-weight: bold">
                  {{ question.question }}
                </p>
              </div>
            </div>
            <div class="row">
              <div id="animate2" class="animate2">
                <p>
                  <button
                    @click="checkedChoice(question.aId)"
                    class="btn btn-question4" :style="style1"
                  >
                    {{ question.a }}
                  </button>
                </p>
              </div>
            </div>
            <div class="row">
              <div id="animate3" class="animate3">
                <p>
                  <button
                    @click="checkedChoice(question.bId)"
                    class="btn btn-question4" :style="style2"
                  >
                    {{ question.b }}
                  </button>
                </p>
              </div>
            </div>
            <div class="row">
              <div id="animate4" class="animate4">
                <p>
                  <button
                    @click="checkedChoice(question.cId)"
                    class="btn  btn-question4" :style="style3"
                  >
                    {{ question.c }}
                  </button>
                </p>
              </div>
            </div>
            <div class="row">
              <div id="animate5" class="animate5">
                <p>
                  <button
                    @click="checkedChoice(question.dId)"
                    class="btn  btn-question4" :style="style4"
                  >
                    {{ question.d }}
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from "@/components";
export default {
  props: {
    question: {},
  },
   data() {
    return {
      style1: null,
      style2: null,
      style3: null,
      style4: null,
    };
  },
  name: "question",
  components: {},
  mounted() {},
  methods: {
    checkedChoice(choiceId) {
      this.style1="";
      this.style2="";
      this.style3="";
      this.style4="";
      if(!this.question.aIsFake){
        this.style1="background-color:green";
      }else if(!this.question.bIsFake){
        this.style2="background-color:green";
      }else if(!this.question.cIsFake){
        this.style3="background-color:green";
      }else if(!this.question.dIsFake){
        this.style4="background-color:green";
      }

      if(choiceId==this.question.aId && this.question.aIsFake){
        this.style1="background-color:red";
      }else if(choiceId==this.question.bId && this.question.bIsFake){
        this.style2="background-color:red";
      }else if(choiceId==this.question.cId && this.question.cIsFake){
        this.style3="background-color:red";
      }else if(choiceId==this.question.dId && this.question.dIsFake){
        this.style4="background-color:red";
      }
      this.$emit("choiceSelected", choiceId);
    },
    reanimate() {
      // console.log("innnn");
      document.getElementById("animate1").classList.remove("animate1");
      setTimeout(() => {
        document.getElementById("animate1").classList.add("animate1");
      }, 10);
      document.getElementById("animate2").classList.remove("animate2");
      setTimeout(() => {
        document.getElementById("animate2").classList.add("animate2");
      }, 10);
      document.getElementById("animate3").classList.remove("animate3");
      setTimeout(() => {
        document.getElementById("animate3").classList.add("animate3");
      }, 10);
      document.getElementById("animate4").classList.remove("animate4");
      setTimeout(() => {
        document.getElementById("animate4").classList.add("animate4");
      }, 10);
      document.getElementById("animate5").classList.remove("animate5");
      setTimeout(() => {
        document.getElementById("animate5").classList.add("animate5");
      }, 10);
    },
  },
  watch: {
    question: function (newVal, oldVal) {
      this.reanimate();
      this.style1="";
      this.style2="";
      this.style3="";
      this.style4="";
    },
    checkSelectedChoice : function(newVal,oldVal){
      if(newVal){

      }
    }
  },
};
</script>

<style>
.animate1 {
  animation-timing-function: ease;
  position: relative;
  animation: mymove 0.75s;
  animation-fill-mode: forwards;
  width: 100%;

}
.animate2 {
  animation-timing-function: ease;
  position: relative;
  animation: mymove 0.85s;
  animation-fill-mode: forwards;
  float: left;
  color: black;
  width: 100%;
}
.animate3 {
  animation-timing-function: ease;
  position: relative;
  animation: mymove 0.95s;
  animation-fill-mode: forwards;
  float: left;
  color: black;
  width: 100%;

}
.animate4 {
  animation-timing-function: ease;
  position: relative;
  animation: mymove 1.05s;
  animation-fill-mode: forwards;
  float: left;
  color: black;
  width: 100%;

}
.animate5 {
  animation-timing-function: ease;
  position: relative;
  animation: mymove 1.15s;
  animation-fill-mode: forwards;
  float: left;
  color: black;
  width: 100%;

}

@keyframes mymove {
  from {
    left: -500px;
  }
  to {
    left: 0;
  }
}
</style>