<template>
  <div>
    <div class="page-header clear-filter">
      <div class="container">
        <div
          class="row"
          style="
            color: black;
            font-weight: bold;
            text-align: center;
            justify-content: center;
            font-size: large;
          "
        >
          Frage aktualisieren
        </div>
        <div class="card">
          <div
            class="
              tab-content tab-space
              text-left
              tab-content-padding
              text-center
            "
          >
            <div class="container" style="color: black">
              <div class="row" v-if="categories">
                <v-select @input="this.changeCategory" label="name" :options="categories" v-model="selectedCategory"></v-select>
              </div>
              <div class="row" v-if="subCategories">
                <v-select label="name" :options="subCategories" v-model="selectedSubCategory" placeholder="Sub Kategorie"></v-select>
              </div>
              <div class="row" v-if="question">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Text"
                  placeholder="Frage Text"
                  v-model="question.text"
                  type="text"
                >
                </fg-input>
              </div>
              <div class="row" v-if="choiceA">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Auswahl A"
                  placeholder="Auswahl A"
                  v-model="choiceA.text"
                  type="text"
                >
                </fg-input>
              </div>
              <div class="row" v-if="choiceB">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Auswahl B"
                  placeholder="Auswahl B"
                  v-model="choiceB.text"
                  type="text"
                >
                </fg-input>
              </div>
              <div class="row" v-if="choiceC">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Auswahl C"
                  placeholder="Auswahl C"
                  v-model="choiceC.text"
                  type="text"
                >
                </fg-input>
              </div>
              <div class="row" v-if="choiceD">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Auswahl D"
                  placeholder="Auswahl D"
                  v-model="choiceD.text"
                  type="text"
                >
                </fg-input>
              </div>
              <div class="row" v-if="question">
                <div class="col-sm-2 col-2">
                  <n-switch v-model="question.activ"
                      on-text="Aktiv"
                      off-text="Passiv"
                  ></n-switch>
                </div>
                <div class="col-sm-2 col-2">
                  <button
                  v-popover:popover1
                  class="navbar-brand btn btn-choice"
                  @click="updateQuestionWithChoices()"
                >
                  Speichern
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog style="color: black" />
  </div>
</template>

<script>
import { FormGroupInput as FgInput, } from "../../../../components";
import {
  Switch
} from '@/components';
import vSelect from 'vue-select'

export default {
  components: {
    FgInput,
    [Switch.name]: Switch,
    'v-select': vSelect
  },
  data() {
    return {
      baseUrl: "http://" + this.apiBaseHost + ":" + this.apiBasePort,
      question: null,
      choiceA: {text: "Laden..."},
      choiceB: {text: "Laden..."},
      choiceC: {text: "Laden..."},
      choiceD: {text: "Laden..."},
      categories: null,
      selectedCategory: null,
      subCategories: null,
      selectedSubCategory: null,
    };
  },
  mounted() {
    this.choiceA = this.$route.params.choiceA;
    this.choiceB = this.$route.params.choiceB;
    this.choiceC = this.$route.params.choiceC;
    this.choiceD = this.$route.params.choiceD;
    this.question = this.$route.params.question;
    this.question["updated_by"] = 1;
    this.selectedSubCategory = this.question.subCategory;
    if (this.question == null) {
      this.$router.push({
        name: "questionList",
      });
    }
    this.$axios
      .get(this.baseUrl + "/getQuestionChoices/" + this.question.id)
      .then((response) => (
        this.assignChoices(response.data)
      ));
      this.loadCategories()
  },
  methods: {
    loadCategories() {
      this.$axios
      .get(this.baseUrl + "/getCategoryList")
      .then(response => {
        this.categories = response.data
        this.selectedCategory = this.categories.filter(c => c.id == this.question.category)
      })
    },
    assignChoices(choices) {
      this.choiceA = choices.filter(c => c.isFake == false)[0];
      this.choiceB = choices.filter(c => c.isFake)[0];
      this.choiceC = choices.filter(c => c.isFake)[1];
      this.choiceD = choices.filter(c => c.isFake)[2];
    },
    updateQuestionWithChoices() {
      this.question.category = this.selectedCategory.id
      this.question.subCategory = this.selectedCategory.name
      this.updateChoice(this.choiceA)
      this.updateChoice(this.choiceB)
      this.updateChoice(this.choiceC)
      this.updateChoice(this.choiceD)
      this.updateQuestion(this.question);
    },
    updateChoice(choice) {
      this.$axios
      .put(this.baseUrl + "/choises/" + choice.id, choice)
      .then(response=>{
        // check response ok
      })
    },
    changeCategory(cat){
      this.$axios
      .post(this.baseUrl + "/getSubcategory", {
        categoryId: cat.id,
      })
      .then((response) => (
        //TODO burada da gelen data subcategory ye basilacak
        this.subCategories = response.data
        ));
    },
    updateQuestion(q) {
      this.$axios
      .put(this.baseUrl + "/questions/" + q.id, q)
      .then(response=>{
        // check response ok
      })
    }
  },
};
</script>

<style>
</style>