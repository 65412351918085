<template>
  <div>
    <div class="page-header clear-filter">
      <div v-if="!finished" class="container">
        <div
          class="row"
          style="
            color: black;
            font-weight: bold;
            text-align: center;
            justify-content: center;
            font-size: large;
          "
        >
          {{ subCategoryName }} Frage:{{ order }} Total Frage:{{
            questionCount
          }}
        </div>
        <div class="card">
          <div
            class="tab-content tab-space text-left tab-content-padding text-center"
          >
            <div class="container">
              <div class="row">
                <div v-if="selectedQuestion" style="width: 100%">
                  <div style="width: 100%">
                    <div class="row">
                      <div
                        v-if="hasImage"
                        class="col-sm-12 col-md-12 col-lg-4 col-xl-4"
                      >
                        <div
                          class="container"
                          style="height: 300px; align: center"
                        >
                          <img
                            :src="selectedQuestion.imageUrl"
                            style="height: 325px"
                          />
                        </div>
                      </div>
                      <div
                        :class="
                          hasImage
                            ? 'col-sm-12 col-md-12 col-lg-7 col-xl-7'
                            : 'col-12'
                        "
                      >
                        <div class="container">
                          <div class="row">
                            <div class="animate1" id="animate1">
                              <p style="color: black; font-weight: bold">
                                {{ selectedQuestion.question }}
                              </p>
                            </div>
                          </div>
                          <div class="row">
                            <div id="animate2" class="animate2">
                              <p>
                                <button class="btn btn-round btn-question2">
                                  {{ selectedQuestion.answer }}
                                </button>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="animate3" class="animate3">
                <div class="row">
                  <button
                    class="btn btn-round btn-question2"
                    @click="getNextQuestion()"
                  >
                    Nächste Frage
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="finished" class="container">
        <div class="card">
          <div
            class="tab-content tab-space text-left tab-content-padding text-center"
          >
            <div class="container">
              <div class="row">
                <router-link
                  class="btn btn-round btn-question"
                  :to="{
                    name: 'lesenChoose',
                  }"
                >
                  Alle Fragen sind fertig für weiter lesen klicken Sie hier
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lesen",
  bodyClass: "lesen-page",
  data() {
    return {
      baseUrl: "http://" + this.apiBaseHost + ":" + this.apiBasePort,
      questions: null,
      selectedQuestion: null,
      order: null,
      questionCount: null,
      subCategoryName: null,
      hasImage: false,
      finished: true,
    };
  },
  mounted() {
    this.subCategoryName = this.$route.params.subCategoryName;
    if (typeof this.subCategoryName === "undefined") {
      this.$router.push({ path: "lesenChoose" });
    }

    if (this.subCategoryName == "BILD") {
      this.hasImage = true;
    }
    this.$axios
      .post(this.baseUrl + "/getLeseQuestions", {
        subCategoryName: this.subCategoryName,
      })
      .then((response) => {
        this.questions = response.data;
        if (this.questions && this.questions[0]) {
          this.finished = false;
          this.selectedQuestion = this.questions[0];
          this.order = 1;
          this.questionCount = this.questions.length;
        }
      });
  },
  methods: {
    getNextQuestion() {
      if (this.questionCount == this.order) {
        this.finished = true;
      } else {
        this.selectedQuestion = this.questions[this.order];
        this.order++;
        document.getElementById("animate1").classList.remove("animate1");
        setTimeout(() => {
          document.getElementById("animate1").classList.add("animate1");
        }, 1);
        document.getElementById("animate2").classList.remove("animate2");
        setTimeout(() => {
          document.getElementById("animate2").classList.add("animate2");
        }, 1);
        document.getElementById("animate3").classList.remove("animate3");
        setTimeout(() => {
          document.getElementById("animate3").classList.add("animate3");
        }, 1);
      }
    },
  },
};
</script>

<style>
.animate1 {
  animation-timing-function: ease;
  position: relative;
  animation: mymove 0.85s;
  animation-fill-mode: forwards;
  float: left;
  color: black;
}
.animate2 {
  animation-timing-function: ease;
  position: relative;
  animation: mymove 1.25s;
  animation-fill-mode: forwards;
  float: right;
  color: black;
}
.animate3 {
  animation-timing-function: ease;
  position: relative;
  animation: mymove 1.25s;
  animation-fill-mode: forwards;
  float: right;
  color: black;
}
</style>