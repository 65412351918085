var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-header clear-filter"},[(_vm.blok)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",staticStyle:{"color":"black","font-weight":"bold","text-align":"center","justify-content":"center","font-size":"large"}}),_c('div',{staticClass:"row",staticStyle:{"overflow":"auto"}},[_c('div',{staticClass:"card col-md-10 ml-auto col-xl-5 mr-auto",staticStyle:{"float":"left"}},_vm._l((_vm.blok.blok),function(item){return _c('div',{key:item.blockId},[_c('div',{staticClass:"tab-content tab-space text-left tab-content-padding text-center"},[_c('div',{staticClass:"btn-round btn-question2"},[_vm._v(_vm._s(item.blokName))]),_vm._l((_vm.blok.categories.filter(
                  function (f) { return f.blokId == item.blokId; }
                )),function(cat){return _c('div',{key:cat.categoryId},[_c('button',{staticClass:"btn btn-round btn-question",on:{"click":function($event){return _vm.getSubcategory(cat.categoryId)}}},[_vm._v(" "+_vm._s(cat.categoryName)+" ")])])})],2)])}),0),_c('div',{attrs:{"col-md-2":""}}),_c('div',{staticClass:"card col-md-10 ml-auto col-xl-5 mr-auto tab-content tab-space text-left tab-content-padding text-center",staticStyle:{"float":"right"}},[_c('div',{staticClass:"container"},_vm._l((_vm.subCatetory),function(sCat){return _c('div',{key:sCat.subCategoryName},[_c('router-link',{staticClass:"btn btn-round btn-question",attrs:{"to":{
                  name: 'lesen',
                  params: {
                    subCategoryName: sCat.subCategoryName,
                  },
                }}},[_vm._v(" "+_vm._s(sCat.subCategoryName)+" ")])],1)}),0)])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }