var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-header clear-filter"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"tab-content tab-space text-left tab-content-padding text-center"},[_c('div',{staticClass:"row",staticStyle:{"color":"black","font-weight":"bold","text-align":"center","justify-content":"center","font-size":"large"}},[_vm._v(" Bitte Prüfung Type wählen ")]),_c('div',{staticClass:"container"},[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[_c('div',{staticClass:"col-12 col-sm-4"},[_c('div',{attrs:{"col-12":""}},[_c('router-link',{staticClass:"btn  btn-question",staticStyle:{"width":"80%"},attrs:{"to":{
                  name: 'exam',
                  params: {
                  examType: 'all',
                  },
                  }}},[_c('img',{attrs:{"src":"img/ganzeP.jpg"}})])],1),_vm._m(0)]),_c('div',{staticClass:"col-12 col-sm-4"},[_c('div',{attrs:{"col-12":""}},[_c('router-link',{staticClass:"btn  btn-question",staticStyle:{"width":"80%"},attrs:{"to":{
                  name: 'exam',
                  params: {
                  examType: 'block1',
                  },
                  }}},[_c('img',{attrs:{"src":"img/block1.jpg"}})])],1),_vm._m(1)]),_c('div',{staticClass:"col-12 col-sm-4"},[_c('div',{attrs:{"col-12":""}},[_c('router-link',{staticClass:"btn  btn-question",staticStyle:{"width":"80%"},attrs:{"to":{
                  name: 'exam',
                  params: {
                  examType: 'block2',
                  },
                  }}},[_c('img',{attrs:{"src":"img/block2.jpg"}})])],1),_vm._m(2)]),_c('div',{staticClass:"col-12 col-sm-4"},[_c('div',{attrs:{"col-12":""}},[_c('router-link',{staticClass:"btn",staticStyle:{"width":"80%","background-color":"white"},attrs:{"to":{
                  name: 'exam',
                  params: {
                  examType: 'nachSicht',
                  },
                  }}},[_c('img',{attrs:{"src":"img/block2.jpg"}})])],1),_vm._m(3)])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticStyle:{"color":"black"}},[_vm._v(" Block 1 + Block 2")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticStyle:{"color":"black"}},[_vm._v(" Block 1")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticStyle:{"color":"black"}},[_vm._v(" Block 2")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticStyle:{"color":"black"}},[_vm._v("Block 2 Ergänzungsprüfung")])])}]

export { render, staticRenderFns }