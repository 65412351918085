
import Vuex from 'vuex';
import Vue from 'vue';
import axios from 'axios';
import VuexPersistence from 'vuex-persist';
import moment from 'moment';


Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

export const store = new Vuex.Store({
    state: {
        user: {
            id: null,
            examStudentId: null,
            categoryId: null,
            examDate: null,
            examType: null,
            userName: null,
            userBlocked: null,
            blockedDate:null
        },
        token: null,
        status: null,
        showLoginButton: false,

    },
    getters: {
        getUserId: state => state.user.id,
        getUserExam: state => state.user.examStudentId,
        getUserExamCategory: state => state.user.categoryId,
        getUserExamDate: state => state.user.examDate,
        getUserExamType: state => state.user.examType,
        isAuthenticated: state => !!state.token,
        authStatus: state => state.status,
        getUser: state => state.user,
        getShowLoginButton: state => state.showLoginButton
    },
    mutations: {
        setUserId(state, id) {
            state.user.id = id
        },
        setUserExam(state, userExam) {
            state.user.examStudentId = userExam
        },
        setUserExamCategory(state, userExamCategory) {
            state.user.categoryId = userExamCategory
        },
        setUserExamDate(state, userExamDate) {
            state.user.examDate = userExamDate
        },
        setUserExamType(state, examType) {
            state.user.examType = examType
        },
        AUTH_REQUEST: (state) => {
            state.status = 'loading'
        },
        AUTH_SUCCESS: (state, userInfo) => {
            state.status = 'success'
            state.token = userInfo.token
            state.user.id = userInfo.userId
            state.user.userName = userInfo.userName
            state.user.userBlocked = userInfo.userBlocked
            state.user.blockedDate = userInfo.blockedDate
        },
        AUTH_LOGOUT: (state) => {
            state.status = null;
            state.token = null;
            state.user = {};
        },
        AUTH_ERROR: (state) => {
            state.status = 'error'
        },
        updateCurrentUser(state, user) {
            Vue.set(state, 'user', user);
        },
        setShowLoginButton(state, status) {
            state.showLoginButton = status
        },
    },
    actions: {
        AUTH_REQUEST: ({ commit, dispatch }, user) => {
            return new Promise((resolve, reject) => { // The Promise used for router redirect in login
                commit("AUTH_REQUEST")
                axios({ url: '/auth/local', baseURL: 'http://ekoltaxischule.at:1337', data: user, method: 'POST' })
                    .then(resp => {
                        // const token = resp.data.jwt
                        // const userId = resp.data.user.id
                        // const userName = resp.data.user.username
                        // const userBlocked = resp.data.user.blocked
                        if (moment(resp.data.user.blockedDate) > Date.now()) {
                            const userInfo = {
                                token: resp.data.jwt,
                                userId: resp.data.user.id,
                                userName: resp.data.user.username,
                                userBlocked: resp.data.user.blocked,
                                userRole: resp.data.user.role,
                                blockedDate:resp.data.user.blockedDate
                            }
                            localStorage.setItem('user-token', userInfo.token) // store the token in localstorage
                            commit("AUTH_SUCCESS", userInfo)
                            commit("updateCurrentUser", { id: userInfo.userId, userName: userInfo.userName, userBlocked: userInfo.userBlocked, userRole: userInfo.userRole,blockedDate:userInfo.blockedDate });
                            // // you have your token, now log in your user :)
                            // dispatch("USER_REQUEST")
                            if (userInfo.token) {
                                axios.defaults.headers.common['Authorization'] = 'Bearer ' + userInfo.token
                            }
                            resolve(resp)
                        } else {
                            reject("blockedDate")
                        }
                    })
                    .catch(err => {
                        commit("AUTH_ERROR", err)
                        localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
                        reject(err)
                    })
            })
        },
        AUTH_LOGOUT: ({ commit, dispatch }) => {
            return new Promise((resolve, reject) => {
                commit("AUTH_LOGOUT")
                localStorage.removeItem('user-token') // clear your user's token from localstorage
                delete axios.defaults.headers.common['Authorization'];
                resolve()
            })
        }
    },
    plugins: [vuexLocal.plugin]
})