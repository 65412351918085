var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-header clear-filter"},[_c('div',{staticClass:"container",staticStyle:{"color":"black"}},[_c('div',{staticClass:"title"},[_c('router-link',{staticClass:"btn btn-round btn-question",attrs:{"to":{
          name: 'resultSummary',
          params: {
            examStudentId: _vm.examStudentId,
            examDate: _vm.examDate,
            examType: _vm.examType,
          },
        }}},[_vm._v(" Zurück zur Prüfung von "+_vm._s(_vm.$moment(_vm.examDate).format("DD-MM-YYYY HH:mm"))+" ")])],1),_vm._l((_vm.resultDetail),function(question){return _c('div',{key:question.examQuestionId},[_c('question-result-overview',{attrs:{"questionResult":question}})],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }