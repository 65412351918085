<template>
  <div>
    <div class="page-header clear-filter">
      <div v-if="blok" class="container">
        <div
          class="row"
          style="
            color: black;
            font-weight: bold;
            text-align: center;
            justify-content: center;
            font-size: large;
          "
        >
        </div>
        <div class="row" style="overflow: auto">
          <div
            class="card col-md-10 ml-auto col-xl-5 mr-auto"
            style="float: left"
          >
            <div v-for="item in blok.blok" :key="item.blockId">
              <div
                class="tab-content tab-space text-left tab-content-padding text-center"
              >
                <div class="btn-round btn-question2">{{ item.blokName }}</div>
                <div
                  v-for="cat in blok.categories.filter(
                    (f) => f.blokId == item.blokId
                  )"
                  :key="cat.categoryId"
                >
                  <button
                    class="btn btn-round btn-question"
                    @click="getSubcategory(cat.categoryId)"
                  >
                    {{ cat.categoryName }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div col-md-2></div>
          <div
            class="card col-md-10 ml-auto col-xl-5 mr-auto tab-content tab-space text-left tab-content-padding text-center"
            style="float: right"
          >
            <div class="container">
              <div v-for="sCat in subCatetory" :key="sCat.subCategoryName">
                <router-link
                  class="btn btn-round btn-question"
                  :to="{
                    name: 'lesen',
                    params: {
                      subCategoryName: sCat.subCategoryName,
                    },
                  }"
                >
                  {{ sCat.subCategoryName }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lesenChoose",
  bodyClass: "lesenChoose-page",
  data() {
    return {
      baseUrl: "http://" + this.apiBaseHost + ":" + this.apiBasePort,
      blok: null,
      subCatetory: null,
    };
  },
  mounted() {
    this.$axios
      .get(this.baseUrl + "/getBlokWithSubcategories")
      .then((response) => {
        this.blok = response.data;
        // console.log("da", this.blok.blok[0][0].blokName);
      });
  },
  methods: {
    getSubcategory(categoryId) {
      const result = this.blok.blokWithSubcategories.filter(
        (f) => f.categoryId == categoryId
      );

      this.subCatetory = result;
    },
  },
};
</script>

<style>
</style>