<template>
  <div class="page-header clear-filter">
    <div v-if="resultSummary" class="container" style="color: black">
      <div class="title">
        {{
          examType === "all"
            ? "Ganze"
            : examType === "block1"
            ? "Block 1"
            : examType === "block2"
            ? "Block 2" :"nach Sicht"
        }}
        Prüfung von
        {{ $moment(examDate).format("DD-MM-YYYY HH:mm") }}
      </div>
      <br />
      <div v-if="examType === 'all' || examType === 'block1'" class="container">
        <div class="card">
          <div
            class="tab-content tab-space text-left tab-content-padding text-center"
          >
            <div :style="getBlockStyle([1, 2])" class="titleCss">BLOCK 1</div>
            <div class="row">
              <div class="col-md-3">Kategorie</div>
              <div class="col-md-2 col-sm-6 col-6">Richtig Antwort</div>
              <div class="col-md-2 col-sm-6 col-6">Falsche Antwort</div>
              <div class="col-md-1 col-sm-4 col-4">Prozent</div>
            </div>
            <!-- :style ="getBlockStyle([result.categoryId])" -->
            <!-- Kategorileri boyamak icin -->
            <div
              class="row"
              v-for="result in resultSummary.filter(
                (f) => f.categoryId == 1 || f.categoryId == 2
              )"
              :key="result.categoryId"
            >
              <router-link
                style="color: black; font-weight: bold"
                class="col-md-3"
                :to="{
                  name: 'resultDetail',
                  params: {
                    examStudentId: result.examStudentId,
                    categoryId: result.categoryId,
                    examDate: examDate,
                    examType: examType,
                  },
                }"
              >
                <p>{{ result.categoryName }}</p>
              </router-link>
              <div class="col-md-2 col-sm-6 col-6">
                {{ result.rightAnswer }}
              </div>
              <div class="col-md-2 col-sm-6 col-6">
                {{ result.wrongAnswer }}
              </div>
              <div class="col-md-1 col-sm-3 col-3"  :style="getCategoryStyle(result.rightAnswer,result.wrongAnswer)">
                {{ Math.round(result.rightAnswer*100 /(parseInt(result.rightAnswer)+parseInt(result.wrongAnswer)))  }} %
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="examType === 'all' || examType === 'block2'" class="container">
        <div class="card">
          <div
            class="tab-content tab-space text-left tab-content-padding text-center"
          >
            <div :style="getBlockStyle([3, 4, 5, 6, 7])" class="titleCss">
              BLOCK 2
            </div>
            <div class="row">
              <div class="col-md-3">Kategorie</div>
              <div class="col-md-2 col-sm-6 col-6">Richtig Antwort</div>
              <div class="col-md-2 col-sm-6 col-6">Falsche Antwort</div>            
              <div class="col-md-1 col-sm-4 col-4">Prozent</div>
            </div>
            <div
              class="row"
              v-for="result in resultSummary.filter(
                (f) => f.categoryId != 1 && f.categoryId != 2
              )"
              :key="result.categoryId"
            >
              <router-link
                style="color: black; font-weight: bold"
                class="col-md-3"
                :to="{
                  name: 'resultDetail',
                  params: {
                    examStudentId: result.examStudentId,
                    categoryId: result.categoryId,
                    examDate: examDate,
                    examType: examType,
                  },
                }"
              >
                <p>{{ result.categoryName }}</p>
              </router-link>
              <div class="col-md-2 col-sm-6 col-6">
                {{ result.rightAnswer }}
              </div>
              <div class="col-md-2 col-sm-6 col-6">
                {{ result.wrongAnswer }}
              </div>
              <div class="col-md-1 col-sm-3 col-3" :style="getCategoryStyle(result.rightAnswer,result.wrongAnswer)">
                {{ Math.round(result.rightAnswer*100 /9)  }} %
              </div>
            </div>
          </div>
        </div>
      </div>
       <div v-if="examType === 'nachSicht'" class="container">
        <div class="card">
          <div
            class="tab-content tab-space text-left tab-content-padding text-center"
          >
            <div :style="getBlockStyle([3, 4, 7])" class="titleCss">
              Nach Sicht Prüfung
            </div>
            <div class="row">
              <div class="col-md-6">Kategorie</div>
              <div class="col-md-2 col-sm-4 col-4">Richtig Antwort</div>
              <div class="col-md-2 col-sm-4 col-4">Falsche Antwort</div>
              <div class="col-md-1 col-sm-4 col-4">Prozent</div>
            </div>
            <div
              class="row" 
              v-for="result in resultSummary.filter(
                (f) => f.categoryId != 1 && f.categoryId != 2 && f.categoryId != 6 && f.categoryId != 5
              )"
              :key="result.categoryId"
            >
              <router-link
                style="color: black; font-weight: bold"
                class="col-md-6"
                :to="{
                  name: 'resultDetail',
                  params: {
                    examStudentId: result.examStudentId,
                    categoryId: result.categoryId,
                    examDate: examDate,
                    examType: examType,
                  },
                }"
              >
                <p>{{ result.categoryName }}</p>
              </router-link>
              <div class="col-md-2 col-sm-4 col-4">
                {{ result.rightAnswer }}
              </div>
              <div class="col-md-2 col-sm-4 col-4">
                {{ result.wrongAnswer }}
              </div>
              <div class="col-md-1 col-sm-3 col-3" :style="getCategoryStyle(result.rightAnswer,result.wrongAnswer)">
                {{ Math.round(result.rightAnswer*100 /9)  }} %
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseUrl: "http://" + this.apiBaseHost + ":" + this.apiBasePort,
      resultSummary: null,
      examDate: null,
      questionCount: [36, 15, 9, 9, 9, 9, 9],
      minimumRightAnswerCount: [24, 10, 6, 6, 6, 6, 6],
      examType: null,
    };
  },
  name: "resultSummary",
  mounted() {
    let examStudentId = this.$route.params.examStudentId;
    this.examType = this.$route.params.examType;
    this.examDate = this.$route.params.examDate;
    if (
      examStudentId == null ||
      this.examDate == null ||
      this.examType == null
    ) {
      examStudentId = this.$store.getters.getUserExam;
      this.examDate = this.$store.getters.getUserExamDate;
      this.examType = this.$store.getters.getUserExamType;
    } else {
      this.$store.commit("setUserExam", examStudentId);
      this.$store.commit("setUserExamDate", this.examDate);
      this.$store.commit("setUserExamType", this.examType);
    }
    if (examStudentId != null) {
      this.$axios
        .post(this.baseUrl + "/getResultSummary", {
          examStudentId: examStudentId,
        })
        .then((response) => (this.resultSummary = response.data));
    } else {
      this.$router.push({ path: "result" });
    }
  },
  methods: {
    getBlockStyle(categoryId) {
      let bestand = true;
      for (let i = 0; i < categoryId.length; i++) {
        let cId = categoryId[i];
        let result = this.resultSummary.filter(function (data) {
          return data.categoryId == cId;
        })[0];
        // console.log("result", result);
        // console.log("cId", cId);
        if (this.minimumRightAnswerCount[cId - 1] > result.rightAnswer)
          bestand = false;

        // console.log("bestand", bestand);
      }
      return bestand ? "background: green;" : "background: red;";
    },getCategoryStyle(rightAnswer,wrongAnswer) {
      let bestand = true;

        const percent =Math.round(rightAnswer*100 /(parseInt(rightAnswer)+parseInt(wrongAnswer)))  
        if(percent<67)
        bestand=false;
        
      
      return bestand ? "" : "background: red;";
    },
  },
};
</script>

<style>
.titleCss {
  font-weight: bold;
  font-size: large;
}
</style>