<template>
  <div class="page-header clear-filter">
    <div class="container">
      <div class="card">
        <div
          class="
            tab-content tab-space
            text-left
            tab-content-padding
            text-center
          "
        >
          <div class="container" style="color: black">
            <div class="title">Fragenverwaltung</div>
            <div class="row" v-if="categories">
                <div class="col-3">Kategorie Auswählen</div>
                <v-select class="col-6" @input="this.changeCategory" label="name" :options="categories" v-model="selectedCategory"  placeholder="Kategorie"></v-select>
              </div>
            <div class="row" v-if="subCategories">
              <div class="col-3">Subkategorie Auswählen</div>
              <v-select class="col-6" label="name"  :options="subCategories" v-model="selectedSubCategory" placeholder="Subkategorie"></v-select>
            </div>
            <div class="nav-item" v-if="loginButton === false && isAdmin()">
            
              <button
                v-popover:popover1
                class="navbar-brand btn btn-question"
                @click="routeToPage('newQuestion')"
              >
                Neue Frage
              </button>
            
            </div>
            <!-- <div style="margin-bottom: 10px; width: 200px">
              <el-input v-model="filters[0].value"></el-input>
            </div> -->
            <data-tables
              :data="questions"
              :action-col="actionCol"
              style="width: 100%"
            >
              <el-table-column
                prop="text"
                label=" Text"
                sortable="custom"
                :min-width="35"
              ></el-table-column>

    
            </data-tables>
          </div>
        </div>
      </div>
    </div>
    <v-dialog style="color: black" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  components: {
    'v-select': vSelect
  },
  data() {
    return {
      baseUrl: "http://" + this.apiBaseHost + ":" + this.apiBasePort,
      questions: [],
      actionCol: {
        label: "Ändern",
        props: {
          align: "center",
        },
        buttons: [
          {
            props: {
              type: "primary",
              icon: "el-icon-edit",
            },
            handler: (row) => {
              this.editRow(row);
            },
            label: "Bearbeiten",
          },
          
        ],
      },
      filters: [
        {
          value: "",
          search_prop: "username",
        },
      ],
      loginButton: true,
      categories: null,
      selectedCategory: null,
      subCategories: null,
      selectedSubCategory: null,
    };
  },
  mounted() {
    this.loadCategories();
    this.loadQuestions({});
    this.currentUser = this.$store.getters.getUser;
    if (this.currentUser && this.currentUser.id) {
      this.loginButton = false;
      this.$store.commit("setShowLoginButton", false);
    } else {
      this.loginButton = true;
      this.$store.commit("setShowLoginButton", true);
    }
  },
  methods: {
    dateFormat: function (row, column) {
      var date = row[column.property];
      if (date == undefined) {
        return "";
      }
      return this.$moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    editRow(row) {
      this.$router.push({
        name: "editQuestion",
        params: {
          question: row,
        },
      });
    },
    isAdmin() {
      return (
        this.currentUser != null &&
        this.currentUser.userRole != null &&
        this.currentUser.userRole.id == 1
      );
    },
    routeToPage(pageName) {
        this.$router.push('/'+pageName);
         document.getElementById("navbarButton").click();
    },
    loadQuestions() {
      if(this.selectedCategory && this.selectedSubCategory) {
        this.$axios
        .get(this.baseUrl + "/getQuestionList/" + this.selectedCategory.id + "/" + this.selectedSubCategory.name)
        .then((response) => (
          this.questions = response.data
        ));
      }
    },
    loadCategories() {
      this.$axios
      .get(this.baseUrl + "/getCategoryList")
      .then(response => {
        this.categories = response.data
      })
    },
    changeCategory(cat){
      this.$axios
      .post(this.baseUrl + "/getSubcategory", {
        categoryId: cat.id,
      })
      .then((response) => (
        //TODO burada da gelen data subcategory ye basilacak
        this.subCategories = response.data
        ));
    }
  },
  watch: {
    showLoginButton: function (newValue, oldValue) {
      this.currentUser = this.$store.getters.getUser;
      this.loginButton = newValue;
    },
    selectedCategory: function(newVal, oldVal) {
      this.loadQuestions();
    },
    selectedSubCategory: function(newVal, oldVal) {
      this.loadQuestions();
    }
  },
};
</script>

<style >

</style>