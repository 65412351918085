/*!

 =========================================================
 * Vue Now UI Kit - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import App from './App.vue';
// You can change this import to `import router from './starterRouter'` to quickly start development from a blank layout.
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
import axios from 'axios'
import moment from 'moment';
import { store } from './store/store';

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// set language to EN
import locale from 'element-ui/lib/locale/lang/de'

import VueDataTables from 'vue-data-tables'

import VModal from "vue-js-modal"


Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios;
Vue.prototype.$axios.defaults.baseURL = process.env.API_BASE_URL;
Vue.prototype.apiBaseHost = process.env.VUE_APP_BACKEND_HOST;
Vue.prototype.apiBasePort = process.env.VUE_APP_BACKEND_PORT;

Vue.config.productionTip = false;

Vue.use(NowUiKit);

const token = localStorage.getItem('user-token')

if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

Vue.use(ElementUI, { locale })
Vue.use(VueDataTables)
Vue.use(VModal, { dialog: true })


const vueApp = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

// vueApp.use(VueAxios, axios)
