<template>
  <div>
    <div class="page-header clear-filter">
      <div class="container">
        <div
          class="row"
          style="
            color: black;
            font-weight: bold;
            text-align: center;
            justify-content: center;
            font-size: large;
          "
        >
          Frage aktualisieren
        </div>
        <div class="card">
          <div
            class="
              tab-content tab-space
              text-left
              tab-content-padding
              text-center
            "
          >
            <div class="container" style="color: black">
              <div class="row" v-if="categories">
                <v-select @input="changeCategory" label="name" :options="categories" v-model="selectedCategory"></v-select>
              </div>
              <div class="row" v-if="subCategories">
              <v-select label="name"  :options="subCategories" v-model="selectedSubCategory" placeholder="Sub Kategorie"></v-select>
            </div>
              <div class="row">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Text"
                  placeholder="Frage Text"
                  v-model="question.text"
                  type="text"
                >
                </fg-input>
              </div>
              <div class="row">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Auswahl A"
                  placeholder="Auswahl A"
                  v-model="choiceA.text"
                  type="text"
                >
                </fg-input>
              </div>
              <div class="row">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Auswahl B"
                  placeholder="Auswahl B"
                  v-model="choiceB.text"
                  type="text"
                >
                </fg-input>
              </div>
              <div class="row">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Auswahl C"
                  placeholder="Auswahl C"
                  v-model="choiceC.text"
                  type="text"
                >
                </fg-input>
              </div>
              <div class="row">
                <fg-input
                  class="col-sm-12 col-12"
                  label="Auswahl D"
                  placeholder="Auswahl D"
                  v-model="choiceD.text"
                  type="text"
                >
                </fg-input>
              </div>
              <div class="row">
                <div class="col-sm-2 col-2">
                  <n-switch v-model="question.activ"
                      on-text="Aktiv"
                      off-text="Passiv"
                  ></n-switch>
                </div>
                <div class="col-sm-2 col-2">
                  <button
                  v-popover:popover1
                  class="navbar-brand btn btn-choice"
                  @click="createQuestionWithChoices()"
                  >
                    Speichern
                  </button>
                </div>
                <div class="col-sm-8 col-8" v-if="errors">
                    <li v-for="error in errors" :key="error" :class="{'text-danger': true }">
                      {{error}}
                    </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog style="color: black" />
  </div>
</template>

<script>
import { FormGroupInput as FgInput } from "../../../../components";
import {
  Switch
} from '@/components';
import vSelect from 'vue-select'
export default {
  components: {
    FgInput,
    [Switch.name]: Switch,
    'v-select': vSelect
  },
  data() {
    return {
      baseUrl: "http://" + this.apiBaseHost + ":" + this.apiBasePort,
      question: {activ: false, created_by: 1},
      choiceA: {text: "", isFake: false},
      choiceB: {text: "", isFake: true},
      choiceC: {text: "", isFake: true},
      choiceD: {text: "", isFake: true},
      errors: [],
      categories: null,
      selectedCategory: null,
      subCategories: null,
      selectedSubCategory: null,
    };
  },
  mounted() {
    this.loadCategories();
  },
  methods: {
    loadCategories() {
      this.$axios
      .get(this.baseUrl + "/getCategoryList")
      .then(response => {
        this.categories = response.data
      })
    },
    createQuestionWithChoices() {
      this.question.category = this.selectedCategory.id
      this.question.subCategory = this.selectedSubCategory.name
      if(this.checkForm()) {
        this.createQuestion(this.question);
      }
      setTimeout(() => this.errors = [], 2000)
    },
    createChoice(choice) {
      this.$axios
      .post(this.baseUrl + "/choises/", choice)
      .then(response=>{
        // check response ok
      })
    },
    changeCategory(cat){
      this.$axios
      .post(this.baseUrl + "/getSubcategory", {
        categoryId: cat.id,
      })
      .then((response) => (
        this.subCategories = response.data
        ));
    },
    createQuestion(q) {
      this.$axios
      .post(this.baseUrl + "/questions/", q)
      .then(response=>{
        this.choiceA.question = response.data.id
        this.choiceB.question = response.data.id
        this.choiceC.question = response.data.id
        this.choiceD.question = response.data.id
        this.createChoice(this.choiceA)
        this.createChoice(this.choiceB)
        this.createChoice(this.choiceC)
        this.createChoice(this.choiceD)
        this.$router.push({
          name: "questionList",
      });
      })
    },
    checkForm() {
      if(!this.question.text) {
        this.errors.push("Frage text erforderlich");
      }
      if(!this.choiceA.text || !this.choiceB.text || !this.choiceC.text || !this.choiceD.text) {
        this.errors.push("Bitte alle Optionen ausfüllen");
      }
      if(this.errors.length) {
        return false;
      }
      return true;
    }
  },
};
</script>

<style>
</style>