<template>
  <div class="page-header clear-filter">
    <div class="container">
      <div class="card">
        <div
          class="tab-content tab-space text-left tab-content-padding text-center"
        >
          <div class="container" style="color: black">
            <div class="title">Prüfungen</div>
            <div style="display: flex; flex-wrap: wrap">
              <div v-for="exam in examResults" :key="exam.id">
                <router-link
                  class="btn btn-round btn-question"
                  :to="{
                    name: 'resultSummary',
                    params: {
                      examStudentId: exam.id,
                      examDate: exam.created_at,
                      examType: exam.examType,
                    },
                  }"
                >
                  <div>
                    {{
                      exam.examType === "all"
                        ? "Ganze Prüfung"
                        : exam.examType === "block1"
                        ? "Block 1 Prüfung"
                        : exam.examType === "block2" 
                        ? "Block 2 Prüfung "
                        : "Nach Sicht Prüfung"
                    }}
                    {{ $moment(exam.created_at).format("DD-MM-YYYY HH:mm") }}
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseUrl: "http://" + this.apiBaseHost + ":" + this.apiBasePort,
      examResults: {},
    };
  },
  name: "examResult",
  mounted() {
    //userId will change with logged User id
    let userId = this.$store.getters.getUserId;
    if(userId){
    this.$axios
      .post(this.baseUrl + "/getStudentExams", {
        userId: userId,
      })
      .then((response) => (this.examResults = response.data));
    }else{
        this.$router.push('/login');
    }
  },
};
</script>

<style>
</style>